import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared.module';
import { RecruitFimmEventModal } from './recruitfimm-event-modal';
import { UpdateEventDateModalComponent } from './update-event-date-modal.component';

const DECLARATIONS = [RecruitFimmEventModal, UpdateEventDateModalComponent];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule, PipesModule, SharedModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class ModalsModule {}
