import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AppointmentListAbstractComponent,
  EventService,
  EventSharedService,
} from 'src/libs/fe/core-ng/src/lib/features';
import {
  UsersService,
  ContactsServices,
  AuthServices,
} from 'src/libs/fe/core-ng/src/lib/services';
import { DomainValuesService } from 'src/libs/fe/core-ng/src/lib/services/domain-values.service';
import { IContactIndividual, IEvent } from 'src/libs/shared/common/src';
import {
  IBaseCandidate,
  RecruitFimmEventStateLabels,
  RecruitFimmEventTypeLabels,
  RecruitFimmEventStateEnum,
  RecruitFimmEventType,
  RecruitFimmMediumLabels,
} from 'src/libs/shared/recruit/src';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { UpdateEventDateModalComponent } from 'src/app/modals/update-event-date-modal.component';
import { CandidatiApiService } from 'src/app/services/candidati-api.service';

@Component({
  selector: 'interview',
  templateUrl: './interview.component.html',
  styles: [],
  providers: [ConfirmationService],
})
export class InterviewComponent
  extends AppointmentListAbstractComponent
  implements OnInit
{
  gotoEdit() {
    throw new Error('Method not implemented.');
  }
  gotoResult() {
    throw new Error('Method not implemented.');
  }
  types = ['OPERATOR'].map((v) => ({
    key: v,
    value: v,
  }));
  headerLess = false;
  selectedType: string;
  selected: IEvent & {
    cache: { candidate: IBaseCandidate; contact: IContactIndividual };
  };
  stateLabels = RecruitFimmEventStateLabels;
  typeLabels = RecruitFimmEventTypeLabels;
  eventStateCode = RecruitFimmEventStateEnum;
  eventTypeCode = RecruitFimmEventType;
  mediumLabel = RecruitFimmMediumLabels;
  constructor(
    usersServices: UsersService,
    eventService: EventService,
    eventSharedService: EventSharedService,
    contactsService: ContactsServices,
    domainValuesService: DomainValuesService,
    private authService: AuthServices,
    private dialog: MatDialog,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private candidateApi: CandidatiApiService
  ) {
    super(
      usersServices,
      eventService,
      eventSharedService,
      contactsService,
      domainValuesService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService.user().subscribe((user) => {
      const baseItem: MenuItem[] = [
        {
          label: 'Dettagli Candidato',
          icon: 'pi pi-fw pi-search',
          command: () => {
            this.gotoDetail();
          },
        },
        {
          label: 'Vai al Candidato',
          icon: 'pi pi-fw pi-search',
          command: () => {
            this.gotoList();
          },
        },
        {
          label: 'Sposta Evento',
          icon: 'pi pi-fw pi-search',
          command: () => {
            this.openUpdateModal();
          },
        },
      ];
      this.cmItems = baseItem;
    });
  }
  searchBySm(event) {
    this.paginateRequest.form = {
      userId: event,
    };
    super.search();
  }

  gotoDetail() {
    const contact = this.selected.cache.contact as IContactIndividual;
    this.candidateApi
      .getCandidateByContact$(contact._id)
      .subscribe((candidate) => {
        this.router.navigateByUrl(
          `/candidati/details/detail-info?id=${candidate._id}`
        );
      });
  }
  gotoList() {
    const contact = this.selected.cache.contact as IContactIndividual;
    this.candidateApi
      .getCandidateByContact$(contact._id)
      .subscribe((candidate) => {
        this.router.navigateByUrl(`/candidati?id=${candidate?._id}`);
      });
  }
  openUpdateModal() {
    if (!this.selected) return;
    this.dialog
      .open(UpdateEventDateModalComponent, {
        data: {
          event: this.selected,
        },
      })
      .afterClosed()
      .subscribe();
  }
  deleteAction() {
    const selected = this.selected as any;
    this.confirmationService.confirm({
      message: 'sei sicuro di voler cancellare questo evento?',
      header: 'Conferma',
      icon: 'pi pi-info-circle',
      accept: () => {
        if (!selected && !selected._id) return;
        this.eventService.delete(selected._id).subscribe(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Evento',
            detail: 'Evento eliminato correttamente',
          });
        });
      },
      reject: () => {},
    });
  }

  onLazyLoad(event: any): void {
    this.paginateRequest.skip = event.first;
    this.paginateRequest.limit = event.rows;
    super.search();
  }
}
